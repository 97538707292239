








































































































































































































































































































































































.fc-day-today {
    background-color: inherit !important;
}
.fc-event-title {
    font-weight: bold;
}
.fc-event-room {
    padding: 0 1px;
}
.fc-list-event-title {
    font-weight: bold;
}
.fc-list-event-room {
    font-weight: normal;
    padding-left: 14px;
}
